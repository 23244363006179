
/* fix for text problem */
p {
  margin: 2px 0 2px;
}
/*------  albums    ------*/

.albums ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .albums li {
    padding: 8px;
    margin-bottom: 7px;
    background-color :#33b5e5;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  .albums li:hover {
    background-color: #0099cc;
  }
  
  .albums li.active {
    background-color: rgb(11, 108, 138);
  }
  
  /*--------------lightbox----------------------------*/
  .lightbox {  
    clear: both; 
  }
  
  
  .mySlides {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;   
  }
  /* Slideshow container */
  
  .slideshow-container {
    /*max-width: 700px; 
    max-height: 700px;*/
    position: relative;  
    /*margin: auto;
    height: auto !important;*/
    width:100%;
    padding-top: 75.25%;
  }
  
  .slide-image-container{  
    height:100%;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    overflow: hidden; 
    background-color: rgb(218, 216, 216); 
  }
  
  .slideImage{
    display: block;
    height:100%;
    width: auto;  
    margin: auto;
  }
  /* Next & previous buttons */
  
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 3px;
    margin-top: -22px;
    color: rgb(250, 250, 250);
    background-color: rgb(218, 216, 216);
    font-weight: bold;
    font-size: 18px;
    /*transition: 0.6s ease;*/
    border-radius: 0 3px 3px 0;
    /*z-index: 10;*/
  }
  /* Position the "next button" to the right */
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  /* On hover, add a black background color with a little bit see-through */
  
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  /* Caption text */
  
  .text {
    color: #252525;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    /*width: 100%;*/
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(247, 245, 245, 0.5);
    /*margin:0 auto;  
    display: block;*/
  }
  /* Number text (1/3 etc) */
  
  .numbertext {
    color: #838080;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    /*background-color: rgba(247, 245, 245, 0.8);*/
  }
  /* The dots/bullets/indicators */
  
  .dot {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  /* this braking main menu :(
  .active,
  .dot:hover {
    background-color: #717171;
  }
  */
  /* Fading animation */
  
  .imagefade {
    -webkit-animation-name: imagefade;
    -webkit-animation-duration: 1.5s;
    animation-name: imagefade;
    animation-duration: 1.5s;
  }
  
  @-webkit-keyframes imagefade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
  }
  
  @keyframes imagefade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
  }
  /* On smaller screens, decrease text size */
  
  @media only screen and (max-width: 300px) {
    .prev,
    .next,
    .text {
        font-size: 11px
    }
  }
  
  /*-----------scrooler-------*/
  
  #scroller {
    position: relative;
    height: 110px; 
    margin: 0 auto; 
    text-align: center;
  }
  
  .scroller-image {
    width:150px; 
    height: 100px;
  }
  
  #scroller .innerScrollArea {  
    overflow-x: auto; /*<-- for manual scrolling--*/
    overflow-y: hidden; 
    text-align: center;
  }
  
  #scroller ul {  
   
    display: inline;
    padding: 0;
    position: relative;
    
    white-space: nowrap;
    overflow-x: auto;  
  }
  
  #scroller li { 
    display: inline;
  }
  
  .slideshow-test {
    display: inline;
  }
  
  
  
  /*---------image properies--------------------*/
  
  
  .image-props{    
    font-size: 12px;  
   /* background-color: rgb(231, 231, 231)*/
  }
  
  .image-props [class*="col-"] {
    padding: 2px;
  }
  
  [class*='improp-'] {
    display: none
  }
  
  .improp-img{  
    height: 80px;
    /*margin: auto;*/
  }
  
  
  
  /*--------responsive grid--------*/
  .masonry-grid {
    /* Prevent vertical gaps */
    /*line-height: 0;*/
     
    -webkit-column-count: 4;
    -webkit-column-gap:   1em;
    -moz-column-count:    4;
    -moz-column-gap:      1em;
    column-count:         4;
    column-gap:           1em;  
  } 
  
  .masonry-item { /* Masonry bricks or child elements */
    background-color: rgb(255, 254, 254);
    display: inline-block;
    margin: 0 0 1em;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(150, 148, 148);
    border-radius: 5px;    
  }
  
  .masonry-item.active {
    border-width: 2px;
    border-color: rgb(45, 148, 233);
  }
  
  .masonry-item.active .enlargeIconSmall{
    color:  rgb(45, 148, 233);
  }
  
  .masonry-item .gridtext {
    color: #222222;
    font-size: 12px;
    padding: 8px 12px;    
    bottom: 8px;
    width: 100%;    
  }
  
  
  /*----------------------------clearfix------------------------------------------------*/
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  
  /*-------------List as Dropdown ------------------*/
  
  
  /*----------------other dropdown--------------------------*/
  
  
  .my_select {
    margin: 5px;
    border: 1px solid #111;
    background: transparent;
    border-radius: 5px;
    width: 150px;
    padding: 5px 5px 5px 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;    
    background: #fafafa url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==") no-repeat 96% 50%;
  }
  
  
  /* CAUTION: IE hackery ahead */
  
  
  .my_select::-ms-expand { 
      display: none; /* remove default arrow in IE 10 and 11 */
  }
  
  /* target Internet Explorer 9 to undo the custom arrow */
  @media screen and (min-width:0\0) {
    .my_select {
          background:none\9;
          padding: 5px\9;
      }
  }
  
  
  
  /*---------------modal -----------------------------/
  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  /* Modal Content (Image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width1: 700px;
  }
  
  /* Caption of Modal Image (Image Text) - Same Width as the Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  .enlargeIcon {
    color: #b3afaf;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }
  
  .enlargeIconSmall {  
    cursor: pointer;
  }
  
  
  
  
  
  /*----------------------------- New Grid CSS ----------------------------------*/
  .ui-g {      
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;      
      
  }
              
  .ui-g:after {
    content: "";
    clear: both;
    display: table;
  }
              
  .ui-g-1,
  .ui-g-2,
  .ui-g-3,
  .ui-g-4,
  .ui-g-5,
  .ui-g-6,
  .ui-g-7,
  .ui-g-8,
  .ui-g-9,
  .ui-g-10,
  .ui-g-11,
  .ui-g-12 {
      float: left;
      box-sizing: border-box;
      padding: 0.5em;
  }
  
  .ui-g-1 {
      width: 8.3333%;
  }
  
  .ui-g-2 {
      width: 16.6667%;
  }
  
  .ui-g-3 {
      width: 25%;
  }
  
  .ui-g-4 {
      width: 33.3333%;
  }
  
  .ui-g-5 {
      width: 41.6667%;
  }
  
  .ui-g-6 {
      width: 50%;
  }
  
  .ui-g-7 {
      width: 58.3333%;
  }
  
  .ui-g-8 {
      width: 66.6667%;
  }
  
  .ui-g-9 {
      width: 75%;
  }
  
  .ui-g-10 {
      width: 83.3333%;
  }
  
  .ui-g-11 {
      width: 91.6667%;
  }
  
  .ui-g-12 {
      width: 100%;
  }
  
  .ui-g-offset-12 {
    margin-left: 100%;
  }
  
  .ui-g-offset-11 {
    margin-left: 91.66666667%;
  }
  
  .ui-g-offset-10 {
    margin-left: 83.33333333%;
  }
  
  .ui-g-offset-9 {
    margin-left: 75%;
  }
  
  .ui-g-offset-8 {
    margin-left: 66.66666667%;
  }
  
  .ui-g-offset-7 {
    margin-left: 58.33333333%;
  }
  
  .ui-g-offset-6 {
    margin-left: 50%;
  }
  
  .ui-g-offset-5 {
    margin-left: 41.66666667%;
  }
  
  .ui-g-offset-4 {
    margin-left: 33.33333333%;
  }
  
  .ui-g-offset-3 {
    margin-left: 25%;
  }
  
  .ui-g-offset-2 {
    margin-left: 16.66666667%;
  }
  
  .ui-g-offset-1 {
    margin-left: 8.33333333%;
  }
  
  .ui-g-offset-0 {
    margin-left: 0%;
  }
  
  @media screen and (max-width: 40em) {
      .ui-sm-1,
      .ui-sm-2,
      .ui-sm-3,
      .ui-sm-4,
      .ui-sm-5,
      .ui-sm-6,
      .ui-sm-7,
      .ui-sm-8,
      .ui-sm-9,
      .ui-sm-10,
      .ui-sm-11,
      .ui-sm-12 {
          padding: 0.5em;
      }
  
      .ui-sm-1 {
          width: 8.3333%;
      }
  
      .ui-sm-2 {
          width: 16.6667%;
      }
  
      .ui-sm-3 {
          width: 25%;
      }
  
      .ui-sm-4 {
          width: 33.3333%;
      }
  
      .ui-sm-5 {
          width: 41.6667%;
      }
  
      .ui-sm-6 {
          width: 50%;
      }
  
      .ui-sm-7 {
          width: 58.3333%;
      }
  
      .ui-sm-8 {
          width: 66.6667%;
      }
  
      .ui-sm-9 {
          width: 75%;
      }
  
      .ui-sm-10 {
          width: 83.3333%;
      }
  
      .ui-sm-11 {
          width: 91.6667%;
      }
  
      .ui-sm-12 {
          width: 100%;
      }
      
      .ui-sm-offset-12 {
        margin-left: 100%;
      }
  
      .ui-sm-offset-11 {
        margin-left: 91.66666667%;
      }
  
      .ui-sm-offset-10 {
        margin-left: 83.33333333%;
      }
  
      .ui-sm-offset-9 {
        margin-left: 75%;
      }
  
      .ui-sm-offset-8 {
        margin-left: 66.66666667%;
      }
  
      .ui-sm-offset-7 {
        margin-left: 58.33333333%;
      }
  
      .ui-sm-offset-6 {
        margin-left: 50%;
      }
  
      .ui-sm-offset-5 {
        margin-left: 41.66666667%;
      }
  
      .ui-sm-offset-4 {
        margin-left: 33.33333333%;
      }
  
      .ui-sm-offset-3 {
        margin-left: 25%;
      }
  
      .ui-sm-offset-2 {
        margin-left: 16.66666667%;
      }
  
      .ui-sm-offset-1 {
        margin-left: 8.33333333%;
      }
  
      .ui-sm-offset-0 {
        margin-left: 0%;
      }
  }
  
  @media screen and (min-width: 40.063em) {
      .ui-md-1,
      .ui-md-2,
      .ui-md-3,
      .ui-md-4,
      .ui-md-5,
      .ui-md-6,
      .ui-md-7,
      .ui-md-8,
      .ui-md-9,
      .ui-md-10,
      .ui-md-11,
      .ui-md-12 {
          padding: 0.5em;
      }
  
      .ui-md-1 {
          width: 8.3333%;
      }
  
      .ui-md-2 {
          width: 16.6667%;
      }
  
      .ui-md-3 {
          width: 25%;
      }
  
      .ui-md-4 {
          width: 33.3333%;
      }
  
      .ui-md-5 {
          width: 41.6667%;
      }
  
      .ui-md-6 {
          width: 50%;
      }
  
      .ui-md-7 {
          width: 58.3333%;
      }
  
      .ui-md-8 {
          width: 66.6667%;
      }
  
      .ui-md-9 {
          width: 75%;
      }
  
      .ui-md-10 {
          width: 83.3333%;
      }
  
      .ui-md-11 {
          width: 91.6667%;
      }
  
      .ui-md-12 {
          width: 100%;
      }
      
      .ui-md-offset-12 {
        margin-left: 100%;
      }
  
      .ui-md-offset-11 {
        margin-left: 91.66666667%;
      }
  
      .ui-md-offset-10 {
        margin-left: 83.33333333%;
      }
  
      .ui-md-offset-9 {
        margin-left: 75%;
      }
  
      .ui-md-offset-8 {
        margin-left: 66.66666667%;
      }
  
      .ui-md-offset-7 {
        margin-left: 58.33333333%;
      }
  
      .ui-md-offset-6 {
        margin-left: 50%;
      }
  
      .ui-md-offset-5 {
        margin-left: 41.66666667%;
      }
  
      .ui-md-offset-4 {
        margin-left: 33.33333333%;
      }
  
      .ui-md-offset-3 {
        margin-left: 25%;
      }
  
      .ui-md-offset-2 {
        margin-left: 16.66666667%;
      }
  
      .ui-md-offset-1 {
        margin-left: 8.33333333%;
      }
  
      .ui-md-offset-0 {
        margin-left: 0%;
      }
  }
  
  @media screen and (min-width: 64.063em) {
      .ui-lg-1,
      .ui-lg-2,
      .ui-lg-3,
      .ui-lg-4,
      .ui-lg-5,
      .ui-lg-6,
      .ui-lg-7,
      .ui-lg-8,
      .ui-lg-9,
      .ui-lg-10,
      .ui-lg-11,
      .ui-lg-12 {
          padding: 0.5em;
      }
  
      .ui-lg-1 {
          width: 8.3333%;
      }
  
      .ui-lg-2 {
          width: 16.6667%;
      }
  
      .ui-lg-3 {
          width: 25%;
      }
  
      .ui-lg-4 {
          width: 33.3333%;
      }
  
      .ui-lg-5 {
          width: 41.6667%;
      }
  
      .ui-lg-6 {
          width: 50%;
      }
  
      .ui-lg-7 {
          width: 58.3333%;
      }
  
      .ui-lg-8 {
          width: 66.6667%;
      }
  
      .ui-lg-9 {
          width: 75%;
      }
  
      .ui-lg-10 {
          width: 83.3333%;
      }
  
      .ui-lg-11 {
          width: 91.6667%;
      }
  
      .ui-lg-12 {
          width: 100%;
      }
      
      .ui-lg-offset-12 {
        margin-left: 100%;
      }
  
      .ui-lg-offset-11 {
        margin-left: 91.66666667%;
      }
  
      .ui-lg-offset-10 {
        margin-left: 83.33333333%;
      }
  
      .ui-lg-offset-9 {
        margin-left: 75%;
      }
  
      .ui-lg-offset-8 {
        margin-left: 66.66666667%;
      }
  
      .ui-lg-offset-7 {
        margin-left: 58.33333333%;
      }
  
      .ui-lg-offset-6 {
        margin-left: 50%;
      }
  
      .ui-lg-offset-5 {
        margin-left: 41.66666667%;
      }
  
      .ui-lg-offset-4 {
        margin-left: 33.33333333%;
      }
  
      .ui-lg-offset-3 {
        margin-left: 25%;
      }
  
      .ui-lg-offset-2 {
        margin-left: 16.66666667%;
      }
  
      .ui-lg-offset-1 {
        margin-left: 8.33333333%;
      }
  
      .ui-lg-offset-0 {
        margin-left: 0%;
      }
  }
  
  @media screen and (min-width: 90.063em) {
      .ui-xl-1,
      .ui-xl-2,
      .ui-xl-3,
      .ui-xl-4,
      .ui-xl-5,
      .ui-xl-6,
      .ui-xl-7,
      .ui-xl-8,
      .ui-xl-9,
      .ui-xl-10,
      .ui-xl-11,
      .ui-xl-12 {
          padding: 0.5em;
      }
  
      .ui-xl-1 {
          width: 8.3333%;
      }
  
      .ui-xl-2 {
          width: 16.6667%;
      }
  
      .ui-xl-3 {
          width: 25%;
      }
  
      .ui-xl-4 {
          width: 33.3333%;
      }
  
      .ui-xl-5 {
          width: 41.6667%;
      }
  
      .ui-xl-6 {
          width: 50%;
      }
  
      .ui-xl-7 {
          width: 58.3333%;
      }
  
      .ui-xl-8 {
          width: 66.6667%;
      }
  
      .ui-xl-9 {
          width: 75%;
      }
  
      .ui-xl-10 {
          width: 83.3333%;
      }
  
      .ui-xl-11 {
          width: 91.6667%;
      }
  
      .ui-xl-12 {
          width: 100%;
      }
      
      .ui-xl-offset-12 {
        margin-left: 100%;
      }
  
      .ui-xl-offset-11 {
        margin-left: 91.66666667%;
      }
  
      .ui-xl-offset-10 {
        margin-left: 83.33333333%;
      }
  
      .ui-xl-offset-9 {
        margin-left: 75%;
      }
  
      .ui-xl-offset-8 {
        margin-left: 66.66666667%;
      }
  
      .ui-xl-offset-7 {
        margin-left: 58.33333333%;
      }
  
      .ui-xl-offset-6 {
        margin-left: 50%;
      }
  
      .ui-xl-offset-5 {
        margin-left: 41.66666667%;
      }
  
      .ui-xl-offset-4 {
        margin-left: 33.33333333%;
      }
  
      .ui-xl-offset-3 {
        margin-left: 25%;
      }
  
      .ui-xl-offset-2 {
        margin-left: 16.66666667%;
      }
  
      .ui-xl-offset-1 {
        margin-left: 8.33333333%;
      }
  
      .ui-xl-offset-0 {
        margin-left: 0%;
      }
  }
  
  .ui-g-nopad {
      padding: 0;
  }
  

  /*--------------------------------------------------------------------*/



  .a-grid {    
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

/*

  @media (min-width: 600px) {
    .a-grid { grid-template-columns: repeat(2, 1fr); }
  }

  @media (min-width: 900px) {
    .a-grid  { grid-template-columns: repeat(3, 1fr); }
  }
 */ 

  .a-col{  
    padding: 1rem;
  }



  /*-------------------  MENU -------------------------*/

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav div {
  float: left;
  display: block;
 /* color: #f2f2f2;*/
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav div:hover {
  background-color: #ddd;
  color: black;
}

/* removed from application
.active {
  background-color: #4CAF50;
  color: white;
}*/

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

}
/*-------------------  MENU -------------------------*/

/*-------------------  CARD -------------------------*/
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: red;
  background-clip: border-box;
  border: 1px solid blue;
}
/*-------------------  CARD -------------------------*/